<template>
    
    <!-- DIALOG DELETE -->
    <v-dialog v-model="value" width="500">
      <v-card>
        <BarTitle :title="title" @close="close()"></BarTitle>
        <v-card-text>
            <p class="text-center" style="font-size: 24px;">{{message}}</p>
        </v-card-text>

        <v-divider></v-divider>
        
        <v-card-actions>
            <v-row>
                <v-col class="text-right">
                    <v-btn x-large text @click="close()" >FECHAR</v-btn>
                    <v-btn :loading="loading" :disabled="loading" x-large color="error" @click="$emit('confirm')" >Excluir</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>  
    
</template>

<script>
import BarTitle from '@/pages/Components/BarTitle.vue'
export default {
    props: {
        loading:{type:Boolean},
        value:{},
        title:{
            type:String,
            default:'apagar registro'
        },
        message: {
            type: String,
            default: 'Deseja excluir ?'
        }
    },
    components:{
         BarTitle,
    },

    data: () => ({}),
    methods:{
        close(){
            this.$emit('close');
        }
    }
};
</script>