<template>
  <v-container>
    <v-card :loading="loadingMain" :disabled="loadingMain">
      <v-toolbar flat>
        <v-toolbar-title>{{ titlePhisicalProgress }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" small dark fab v-bind="attrs" v-on="on">
              <v-icon>mdi-hammer-wrench </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="getExportExcel()">
              <v-list-item-title><v-icon class="mr-2"> mdi-file-excel </v-icon>
                Exportar Excel
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn v-if="!view" :loading="loadingSave" :disabled="loadingSave" rounded large color="primary"
          style="width: 150px;" @click="save()"> Salvar</v-btn>
        <v-btn v-if="view" rounded large color="secondary" style="width: 150px;" @click="back()">
          <v-icon>mdi-arrow-left</v-icon>Voltar</v-btn>
      </v-toolbar>

      <v-row class="mt-4">
        <v-col>
          <v-text-field class="ml-4" :disabled="view" v-model="dateStart" :error-messages="dateStartErro"
            label="Data Inicial" outlined type="date"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :disabled="view" v-model="dateEnd" :error-messages="dateEndErro" label="Data Final" outlined
            type="date"></v-text-field>
        </v-col>
        <v-col>
          <v-select :disabled="view" class="mr-4" v-model="cost" :error-messages="cost_error" @change="loadServices()"
            return-object :items="itemsCost" item-text="name" item-value="id" outlined label="Custo">
          </v-select>
        </v-col>
      </v-row>
      <!-- ETAPAS  -->
      <div v-for="stage in stages" :key="stage[0].id">
        <div class="title_table">
          <div style="float: left">

          </div>
          <div style="float: left">
            {{ stage[0].code + " - " + stage[0].name }}
          </div>
          <!-- <div style="float: right">Total: {{ calcTotal(stage[0].id) }}</div> -->
        </div>
        <!-- COMPOSICOES -->
        <v-card elevation="0">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class=" text-left">SERVIÇO</th>
                  <th class=" text-center">UNID.</th>
                  <th class=" text-center">QUANT.</th>
                  <th class=" text-right">HH (UNIT.)</th>
                  <th class=" text-right">HH TOTAL</th>
                  <th class=" text-right" style="white-space: nowrap">% REALIZADO</th>
                  <th class=" text-right" style="width: 100px; white-space: nowrap">HH REALIZADO</th>
                  <th class=" text-right" style="white-space: nowrap">A REALIZAR</th>
                  <th class=" text-center"></th>

                </tr>
              </thead>

              <tbody>
                <tr v-for="composition in compositions[stage[0].id]" :key="composition.id">
                  <td style="width:550px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ `${composition.composition_group.code} - ${composition.code} -
                          ${composition.name.substring(0, 150)}
                          ${stringLong(composition.name)}` }}
                        </div>
                      </template>
                      <span>
                        {{ composition.composition_group.code + "-" + composition.code + ' - ' +
                          composition.name }}
                      </span>
                    </v-tooltip>


                  </td>
                  <td class="text-center">
                    <div style="width:100px">{{ composition.unity.trim() }}</div>
                  </td>
                  <td class="text-center">
                    {{ formatMoney(composition.pivot.quantity) }}
                  </td>
                  <td class="text-right">
                    <v-edit-dialog :return-value.sync="composition.pivot.hh_unit" large cancel-text="CANCELAR"
                      save-text="SALVAR" @save="calcHH(composition)">
                      <div style=" width:100px" class="editable">
                        {{ formatMoney(composition.pivot.hh_unit) }}
                      </div>
                      <template v-slot:input>
                        <vuetify-money v-model="composition.pivot.hh_unit" :clearable="true" valueWhenIsEmpty="0"
                          :options="{
                            locale: 'pt-BR',
                            length: 11,
                            precision: 5,
                          }" />
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td class="text-right">
                    <div style="width:100px">
                      {{ formatMoney(composition.pivot.hh_total) }}
                    </div>
                  </td>
                  <td class="text-right">
                    <v-edit-dialog :return-value.sync="composition.pivot.percentage_realized" large
                      cancel-text="CANCELAR" save-text="SALVAR" @save="calcHH(composition)">
                      <div style=" width:100px" class="editable">
                        {{ formatMoney(composition.pivot.percentage_realized) }}
                      </div>
                      <template v-slot:input>
                        <vuetify-money v-model="composition.pivot.percentage_realized" :clearable="true"
                          valueWhenIsEmpty="0" :options="{
                            locale: 'pt-BR',
                            length: 11,
                            precision: 5,
                          }" />
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td class="text-right">
                    {{ formatMoney(composition.pivot.hh_realized) }}

                  </td>
                  <td class="text-right">
                    {{ formatMoney(composition.pivot.a_realizar) }}

                  </td>
                  <td class="text-right">
                    <div style="text-wrap: nowrap;">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-if="physicalProgressId" :disabled="view" v-bind="attrs" v-on="on"
                            @click="showDetails(composition)" icon color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-if="physicalProgressId" :disabled="view" v-bind="attrs" v-on="on"
                            @click="showViewDetails(composition)" icon><v-icon>mdi-magnify</v-icon></v-btn>
                        </template>
                        <span>Visualizar</span>
                      </v-tooltip>


                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4" style="background: lightgray;"></td>
                  <td class="text-right" style="width:100px;background: lightgray;">{{
                    formatMoney(calcSumHHTotal(compositions[stage[0].id])) }}</td>
                  <td class="text-right" style="width:100px;background: lightgray;">{{
                    formatMoney(calcSumPercentageRealized(compositions[stage[0].id])) }}</td>
                  <td class="text-right" style="width:100px;background: lightgray;">{{
                    formatMoney(calcSumHHRealized(compositions[stage[0].id])) }}</td>
                  <td class="text-right" style="width:100px;background: lightgray;">{{
                    formatMoney(calcSumARealizar(compositions[stage[0].id])) }}</td>
                  <td style="background: lightgray;"></td>
                </tr>

              </tfoot>
            </template>
          </v-simple-table>
        </v-card>
      </div>
      <!-- barra total -->
      <div class="title_table" v-if="compositions.length != 0">
        <v-row>
          <v-col class="">
            HH TOTAL : {{ formatMoney(totalHH) }}
          </v-col>

          <v-col class="">
            REALIZADO (%) : {{ formatMoney(totalHHRealizedPercentage) }}
          </v-col>

          <v-col class="">
            HH REALIZADO :{{ formatMoney(totalHHRealized) }}
          </v-col>
          <v-col class="text-right">
            A REALIZAR :{{ formatMoney(totalHHARealizar) }}
          </v-col>

        </v-row>
      </div>
    </v-card>
    <DialogDetails :view="viewDetails" @refresh_composition="refreshComposition"
      :physical_progress_item_description="physicalProgressItemDescription"
      :physical_progress_item_id="physicalProgressItemId" :physical_progress_item="physicalProgressItem"
      :physical_progress="physicalProgress" v-model="dialogDetails"></DialogDetails>
  </v-container>
</template>

<script>

import service from "../service";
import { mapActions } from "vuex";
// import BarTitle from '@/pages/Components/BarTitle.vue';
import DialogDetails from './DialogDetailsPhysicalProgress.vue'


export default {
  components: {
    DialogDetails,
  },
  data: () => ({
    physicalProgressChildren: true,
    physicalProgress: {},
    viewDetails: false,
    view: false,
    physicalProgressItemId: null,
    physicalProgressItem: null,
    physicalProgressItemDescription: '',
    dialogDetails: false,
    compositions: [],
    loadingMain: false,
    loadingSave: false,
    grandTotal: 0,
    stages: [],
    itemsCost: [],
    cost: {},
    costId: null,
    dateStart: '',
    dateEnd: '',
    dateStartErro: '',
    dateEndErro: '',
    cost_error: '',
    description: '',
    description_erro: '',
    totalHH: 0,
    totalHHRealized: 0,
    totalHHARealizar: 0,
    totalHHRealizedPercentage: 0
  }),
  computed: {
    // ...mapGetters("Cost", ["getCostId"]),
    titlePhisicalProgress() {
      let title = 'Novo Avanço Fisico'

      if (Object.keys(this.cost).length > 0) {
        title = this.cost.name + '  ( ' + this.formatDate(this.dateStart) + ' - ' + this.formatDate(this.dateEnd) + ') ' + this.formatMoney(this.totalHHRealizedPercentage) + '%'
      }

      return title
    }
  },
  watch: {

    dialogDetails() {
      //quando fechas o dialogo de detalhes desativa o modo de visualizacao
      if (this.dialogDetails == false) {
        this.viewDetails = false
      }
    }
  },

  created() {

    this.dateStart = (new Date()).toISOString().substring(0, 10)
    this.dateEnd = (new Date()).toISOString().substring(0, 10)
    this.loadSelectCost()
    this.view = this.$route.params.view ? true : false

    //se for editar  
    this.physicalProgressId = this.$route.params.physical_progress_id
    if (this.physicalProgressId) {
      this.loadPhysicalProgress(this.physicalProgressId)
    }
  },

  methods: {
    ...mapActions('PhysicalProgress', [
      'getServices',
      'getCostSelect',
      'store',
      'update',
      'loadDataPhysicalProgress',
      'exportExcel'
    ]),
    save() {

      // validacao

      if (this.dateStart == '' || this.dateStart == null) {
        this.dateStartErro = 'Campo Obigatório'
        this.$toast.error('Data inicial é um campo obrigatório')
        return false
      }

      if (this.dateEnd == '' || this.dateEnd == null) {
        this.dateEndErro = 'Campo Obigatório'
        this.$toast.error('Data final é um campo obrigatório')
        return false
      }

      if (this.cost.id == '' || this.cost.id == null) {
        this.cost_error = 'Campo obrigatório'
        this.$toast.error('Custo é um campo obrigatório')
        return false
      }

      

      // return false
      this.loadingSave = true
      let data = {
        physical_progress_id: this.physicalProgressId,
        cost_id: this.cost.id,
        description: this.titlePhisicalProgress,
        items: this.compositions,
        date_start: this.dateStart,
        date_end: this.dateEnd,
        realized_percentage: this.totalHHRealizedPercentage,
      }
      
      
      if (this.physicalProgressId) {
        //update
        this.update(data).then(() => {
          this.$toast.success("Salvo com sucesso");
          this.$router.push('/system/physical_progress/physical_progress')
        }).finally(() => {
          this.loadingSave = false
        })
      } else {
        //store
        this.store(data).then(() => {
          this.$toast.success("Salvo com sucesso");
          this.$router.push('/system/physical_progress/physical_progress')
        }).finally(() => {
          this.loadingSave = false
        })
      }

    },
    back() {
      this.$router.push('/system/physical_progress/physical_progress')
    },
    refreshComposition(composition_pivot) {
      this.physicalProgressItem.pivot = Object.assign({}, composition_pivot)
      this.grandTotalHhTotal()
    },
    showDetails(physical_progress_item) {
      this.physicalProgress = {
        physical_progress_id: this.physicalProgressId,
        date_start: this.dateStart,
        date_end: this.dateEnd
      }

      this.physicalProgressItem = physical_progress_item
      this.physicalProgressItemDescription = physical_progress_item.name
      this.physicalProgressItemId = physical_progress_item.pivot.id
      this.dialogDetails = true

    },
    showViewDetails(physical_progress_item) {
      this.physicalProgress = {
        physical_progress_id: this.physicalProgressId,
        date_start: this.dateStart,
        date_end: this.dateEnd
      }

      this.viewDetails = true
      this.physicalProgressItem = physical_progress_item
      this.physicalProgressItemDescription = physical_progress_item.name
      this.physicalProgressItemId = physical_progress_item.pivot.id
      this.dialogDetails = true

    },
    loadSelectCost() {
      this.getCostSelect().then((response) => {
        this.itemsCost = response.data
      })
    },

    loadDataCost() {
      console.log(this.costId)
    },

    loadPhysicalProgress(physical_progress_id) {
      this.loadingMain = true
      this.loadDataPhysicalProgress(physical_progress_id)
        .then((response) => {
          this.stages = response.data.stages
          this.compositions = response.data.compositions
          this.description = response.data.physical_progress.description
          this.physicalProgressChildren = response.data.physical_progress.physical_progress_id // se null entao é pai é o primeiro avanço
          this.cost = response.data.cost
          this.dateStart = response.data.physical_progress.date_start
          this.dateEnd = response.data.physical_progress.date_end

          //calc p total
          this.grandTotalHhTotal()
        }).finally(() => {
          this.loadingMain = false
        })
    },

    stringLong(composition_name) {
      if (composition_name.length > 150) {
        return '...'
      }
      return ''
    },
    calcSumHHTotal(stage) {
      return stage.reduce((acc, composition) => {
        return acc + parseFloat(composition.pivot.hh_total)
      }, 0)


    },
    calcSumHHRealized(stage) {
      return stage.reduce((acc, composition) => {
        return acc + parseFloat(composition.pivot.hh_realized)
      }, 0)
    },
    calcSumARealizar(stage) {
      return stage.reduce((acc, composition) => {
        return acc + parseFloat(composition.pivot.a_realizar)
      }, 0)
    },

    calcSumPercentageRealized(stage) {
      return (this.calcSumHHRealized(stage) / this.calcSumHHTotal(stage)) * 100
    },


    loadServices() {
      this.cost_error = ''
      this.loadingMain = true
      this.getServices(this.cost.id)
        .then((result) => {
          this.stages = result.data.stages;
          this.compositions = result.data.compositions;
          //calc p total
          this.grandTotalHhTotal()

        })
        .catch()
        .finally(() => {
          this.loadingMain = false
        });
    },
    calcHH(composition) {

      //calc hh total
      let hh_total = composition.pivot.quantity * composition.pivot.hh_unit
      composition.pivot.hh_total = hh_total

      //calc hh realizado
      if (parseFloat(composition.pivot.percentage_realized) > 0) {
        let hh_realized = (composition.pivot.percentage_realized / 100) * composition.pivot.hh_total
        composition.pivot.hh_realized = hh_realized
      } else {
        composition.pivot.hh_realized = 0
      }

      // calc a realizar
      let a_realizar = composition.pivot.hh_total - composition.pivot.hh_realized
      composition.pivot.a_realizar = a_realizar

      //força a tualizacao da tabela
      this.updateTable()

      //atualiza o total geral
      this.grandTotalHhTotal()
    },
    updateTable() {
      //gabiarra pra atualizar a tabela
      let compositions = this.compositions
      this.compositions = []
      this.compositions = compositions
    },

    grandTotalHhTotal() {
      this.totalHH = 0
      this.totalHHRealized = 0

      if (this.compositions != undefined) {
        //HH TOTAL
        Object.keys(this.compositions).forEach(stage_id => {
          this.totalHH += this.compositions[stage_id].reduce((acc, composition) => {
            return acc + parseFloat(composition.pivot.hh_total)
          }, 0)
        });

        //TOTAL HH REALIZADO
        Object.keys(this.compositions).forEach(stage_id => {
          this.totalHHRealized += this.compositions[stage_id].reduce((acc, composition) => {
            return acc + parseFloat(composition.pivot.hh_realized)
          }, 0)
        });

        //TOTAL PERCENTAGE HH
        this.totalHHRealizedPercentage = (this.totalHHRealized / this.totalHH) * 100
        if(isNaN(this.totalHHRealizedPercentage)  ){
          this.totalHHRealizedPercentage = 0
        }

        //TOTAL HH A REALIZAR
        Object.keys(this.compositions).forEach(stage_id => {
          this.totalHHARealizar += this.compositions[stage_id].reduce((acc, composition) => {
            return acc + parseFloat(composition.pivot.a_realizar)
          }, 0)
        });



      }
    },
    getExportExcel() {
      this.exportExcel(this.physicalProgressId)
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'export.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();

        })
    },


    getAllStages(val) {
      this.loadingSearchStage = true;
      service
        .searchStage(val)
        .then((result) => {
          this.itemsSearchStage = result.data;
        })
        .catch()
        .finally(() => {
          this.loadingSearchStage = false;
        });
    },



    formatMoney(number) {
      number = this.parseNumber(number);
      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    parseNumber(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result;
    },
    formatDate(date) {
      let date_split = date.split("-");
      return date_split[2] + "/" + date_split[1] + "/" + date_split[0];
    },


  },
};
</script>

<style scoped>
.title_table {
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  height: 32px;
  background: #1976d2;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  padding-top: 3px;
}

.editable {
  color: #1976d2;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.tax {
  box-sizing: border-box;
  padding-right: 16px;
  padding-left: 16px;
  height: 30px;
  font-weight: bold;
}

.back_tax {
  background: lightgray;
}

.back_total {
  background: #1976d2;
  color: white;
}
</style>
